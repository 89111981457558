@tailwind base;
@tailwind components;
@tailwind utilities;

.mt-4.grid.grid-cols-1.gap-4.sm\:grid-cols-2.lg\:grid-cols-3.xl\:grid-cols-4 {
    margin-left: 2%;
    margin-right: 2%;
}
 
body, pre {
    font-family: 'Roboto', sans-serif !important;
}